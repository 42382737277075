import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

class Privacy extends React.Component {
    componentDidMount(){
        document.title = "Privacy - Qvision"
    }

    render() {
        return (
            <>
                <Header header="BackHeader" />
        
                <main className="o-page__main" id="main">
                    <h1 className="u-spacing-pt u-constrain">Privacyverklaring</h1>
        
                    <div class="u-constrain  u-spacing-pv++ u-bg-white u-spacing-mb+">
                        <h3 aria-level="2">
                            Dienstverlening naar een nieuw digitaal niveau
                        </h3>
        
                        <p>
                            Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen. Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen.
                        </p>
        
                        <h3 aria-level="2">
                            Dienstverlening naar een nieuw digitaal niveau
                        </h3>
        
                        <p>
                            Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen. Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen.
                        </p>
        
                        <h3 aria-level="2">
                            Dienstverlening naar een nieuw digitaal niveau
                        </h3>
        
                        <p>
                            Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen. Qvision is een ICT-bedrijf wat zich voornamelijk richt op het leveren van diensten en oplossingen voor woningcorporaties. Wij ontwikkelen een breed scala aan interactieve, web-based oplossingen zoals (mobiele) websites, portalen, BackOffice oplossingen en woonruimte verdeel systemen.            
                        </p>
        
                    </div>
                </main>
        
                <Footer footer="small"/>
            </>
        );
    }
}

export default Privacy;