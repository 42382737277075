import React from 'react';
import FooterContact from './FooterContact';
import FooterSupport from './FooterSupport';
import SocialMedia from './SocialMedia';

class Footer extends React.Component {
    render() {
        return (
            <footer className="c-footer  o-page__footer" id="footer">
                <div className="c-footer__content">
                    <div className="o-grid">
                        {(this.props.footer !== "small") ? 
                            <>
                                <div className="o-grid__row">
                                    <div className="o-grid__item" data-fraction="6">
                                        <FooterContact />
                                    </div>
                                    <div className="o-grid__item" data-fraction="6">
                                        <FooterSupport />
                                    </div>
                                </div>
            
                                <div className="o-grid__row">
                                    <div className="o-grid__item" data-fraction="12">
                                        <SocialMedia />
                                    </div>
                                </div>
                            </> : ''
                        }

                        <div className="o-grid__row">
                            <div className="o-grid__item  c-footer__body" data-fraction="6">
                                <a href="https://wijzijnumbrella.nl/privacy-policy/" className="u-color-white">Privacyverklaring</a>&nbsp;
                                {/* TODO: add an url  */}
                                <a href="https://wijzijnumbrella.nl/responsible-disclosure-statement/" className="u-spacing-ml-  u-color-white">Responsible Disclosure Statement</a>
                            </div>
                            <div className="o-grid__item" data-fraction="6">
                                <div className="c-footer__logo"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    };
}

export default Footer;