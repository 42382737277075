import React from 'react';

const SocialMedia = () => (
    <>
        <a href="https://www.facebook.com/qvisionnl" className="c-social-media__link" title="facebook">
            <span className="c-social-media__icon  c-icon" data-icon="facebook"></span>
        </a>     
        <a href="http://twitter.com/qvisionnl" className="c-social-media__link" title="twitter">
            <span className="c-social-media__icon  c-icon" data-icon="twitter"></span>     
        </a>     
        {/* TODO: add an url  */}
        {/* <a href="/" class="c-social-media__link" title="instagram">
            <span class="c-social-media__icon  c-icon" data-icon="instagram"></span>     
        </a>      */}
        <a href="http://www.linkedin.com/company/qvision_2" className="c-social-media__link" title="linkedin">
            <span className="c-social-media__icon  c-icon" data-icon="linked-in"></span>                
        </a>
    </>
);

export default SocialMedia;