import React from 'react'

class BackButton extends React.Component {
    static contextTypes = {
      router: () => true
    }
  
    render() {
      return (
        <a className="c-button  c-button--transparent  c-button--icon" href="/" aria-labelledby="backLabel">
            <span className="c-icon" data-icon="arrow--green"></span>
            <h2 id="backLabel" className="u-spacing-pl">Terug</h2>
        </a>
      )
    }
  }

  export default BackButton;