import React from 'react';
import BackButton from './BackButton';
import HeaderExtra from './HeaderExtra';
import HeaderLogo from './HeaderLogo';

class Header extends React.Component {
    render() {
        return (
            <header className="o-page__header">
                <div className="c-header">     
                    {(this.props.header === "BackHeader") ?  
                        <BackButton /> : 
                        <HeaderLogo />
                    }
                    {(this.props.header !== "LogoOnly" && this.props.header !== "BackHeader") ?  
                        <HeaderExtra /> : ''
                    }
                </div>
            </header>
        )
    };
}

export default Header;