import React from 'react';

const HeaderExtra = () => (
    <nav className="c-header__extra u-hidden@s-down" aria-label="Hoofdmenu">
        <ul className="c-header__list">
            <li className="c-header__item">
                <a className="c-header__link" href="#producten">Producten</a>
            </li>
            <li className="c-header__item">
                <a className="c-header__link" href="#vacatures">Vacatures</a>
            </li>
            <li className="c-header__item">
                <a className="c-button" href="#contact">Contact</a>
            </li>
        </ul>
    </nav>
);

export default HeaderExtra;