import React from 'react';

const FooterContact = () => (
    <section aria-label="Contact">
        <h2 className="u-color-white">Contact</h2>
        <div className="o-grid">
            <div className="c-footer__body  o-grid__row">
                <div className="o-grid__item" data-fraction="3">
                    Qvision<br />
                    De Waal 34<br />
                    5684 PH Best
                </div>
                <div className="o-grid__item" data-fraction="6">
                    Telefoonnummer: 0499 - 499 444<br />
                    E-mailadres: <a href="mailto:sales@qvision.nl" className="u-color-white">sales@qvision.nl</a>
                </div>
            </div>
        </div>
    </section>
);

export default FooterContact;