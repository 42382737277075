import React from 'react';

const FooterSupport = () => (
    <section aria-label="Support">
        <h2 className="u-color-white">Support</h2>
        <div className="c-footer__body">
            Support: 0499 - 499 441<br />
            Of log in op het <a className="u-color-white" href="https://serviceportaal.qvision.nl/">Serviceportaal</a>
        </div>
    </section>
);

export default FooterSupport;