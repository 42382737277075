import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Icon from '../components/Icon';

class Home extends React.Component {
    state = {
        heart: "",
        beat: false
    }

    componentDidMount(){
        document.title = "Home - Qvision";
        document.addEventListener("keydown", (e) => {
            var easterEgg = this.state.heart;

            easterEgg += String.fromCharCode(e.keyCode);
            if(easterEgg.match(/heart/gi)) {
                this.setState({beat: !this.state.beat});
                easterEgg = "";
                this.forceUpdate();
            }
            this.setState({heart: easterEgg});
        }, false);
    }

//   c-animation--beat

    render() {
        return (
            <>
                <Header />
            
                <main className="o-page__main" id="main">
                    <div className="c-teaser" aria-label="Teaser">
                        <h1 className="c-teaser__head">Qvision hartje Umbrella</h1>
                        <p className="c-teaser__paragraph  u-fs-base  u-flex">Sinds 11 april 2018 zijn wij samengegaan met Umbrella.</p>
                        <div className="c-teaser__button">
                            <a href="https://wijzijnumbrella.nl/blog/organisatienieuws/qvision-en-umbrella-bundelen-krachten/" className="c-button c-button--secondary">Lees verder</a>
                        </div>

                        <div className={"c-teaser__image" + (this.state.beat ? "  c-animation--beat" : "")}>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 312 290">
                                <path fill="#0D9EB0" d="M311.484 86.475C306.93 36.363 271.45.006 227.047.006c-29.582 0-56.668 15.914-71.909 41.42C140.035 15.59 114.06 0 84.952 0 40.555 0 5.069 36.357.521 86.47c-.36 2.213-1.837 13.862 2.652 32.86 6.468 27.4 21.41 52.325 43.197 72.06L155.066 290l110.563-98.604c21.788-19.741 36.729-44.66 43.197-72.067 4.488-18.991 3.012-30.64 2.658-32.854zm-14.34 30.107c-5.905 25.026-19.592 47.826-39.543 65.894l-102.463 91.388L54.434 182.5c-19.987-18.104-33.668-40.898-39.578-65.924-4.249-17.978-2.503-28.133-2.497-28.2l.09-.605c3.9-43.904 34.389-75.774 72.503-75.774 28.124 0 52.881 17.276 64.63 45.08l5.526 13.094 5.527-13.095c11.562-27.377 37.628-45.073 66.418-45.073 38.108 0 68.602 31.87 72.58 76.344.013.096 1.759 10.257-2.49 28.235z"/>
                            </svg>
                        </div>
                    </div>

                    <section className="u-bg-white" id="producten" aria-label="Producten">
                        <div className="u-spacing-pv++  u-spacing-ph">
                            <h2 className="u-text-center">Het Umbrella Platform bestaat uit:</h2>
                            <div className="o-grid  u-hidden@s-down">
                                <div className="o-grid__row  u-text-center"> 
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="klantportaal" />
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="kcc" />
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="wrv" />
                                    </div>  
                                </div>
                                <div className="o-grid__row  u-text-center  u-spacing-mt"> 
                                    <div className="o-grid__item" data-fraction="4">
                                        <h3>Klantportaal</h3>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <h3>Klant Contact Center</h3>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <h3>Woonruimteverdeling</h3>
                                    </div>  
                                </div>
                                <div className="o-grid__row  u-font-roboto  u-text-center  u-spacing-mt-"> 
                                    <div className="o-grid__item" data-fraction="4">
                                        <p>Laat klanten online zelfstandig zaken regelen en afhandelen.</p>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <p>Alle klantcontactmomenten op één tijdlijn.</p>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <p>De meest complete oplossingen voor woonruimteverdeling.</p> 
                                    </div>  
                                </div>
                                <div className="o-grid__row  u-text-center  u-spacing-mt"> 
                                    <div className="o-grid__item" data-fraction="4">
                                        <a href="https://wijzijnumbrella.nl/klantportaal/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <a href="https://wijzijnumbrella.nl/klant-contact-center/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <a href="https://wijzijnumbrella.nl/woonruimteverdeling/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>  
                                </div>
                            </div>

                            <div className="o-grid  u-hidden@m-up">
                                <div className="o-grid__row  u-text-center"> 
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="klantportaal" />
                                        <h3>Klantportaal</h3>
                                        <p>Laat klanten online zelfstandig zaken regelen en afhandelen.</p>
                                        <a href="https://wijzijnumbrella.nl/klantportaal/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="kcc" />
                                        <h3>Klant Contact Center</h3>
                                        <p>Alle klantcontactmomenten op één tijdlijn.</p>
                                        <a href="https://wijzijnumbrella.nl/klant-contact-center/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>
                                    <div className="o-grid__item" data-fraction="4">
                                        <Icon icon="wrv" />
                                        <h3>Woonruimteverdeling</h3>
                                        <p>De meest complete oplossingen voor woonruimteverdeling.</p> 
                                        <a href="https://wijzijnumbrella.nl/woonruimteverdeling/" className="c-button c-button--secondary">Lees verder</a>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </section>
            
                    <section id="vacatures" aria-label="Vacatures">
                        <div className="o-grid  u-constrain  u-spacing-pv++">
                            <div className="o-grid__row">  
                                <div className="o-grid__item" data-fraction="3">
                                    <h2>Vacatures</h2>
                                </div>
                                <div className="o-grid__item" data-fraction="9">
                                    <h2 className="u-color-secondary">Kom jij bij ons werken?</h2>
                                    <p className="u-font-roboto">
                                        Bij Qvision werken we hard aan uitdagende webprojecten waarbij innovatie voorop staat. Daarnaast maken we ook tijd voor kennisontwikkeling en gezelligheid in de vorm van kennissessies, kwartaalborrels, bedrijfsuitjes, pokeravonden en nog veel meer.
                                        <br/>
                                        <br/>
                                        Wij zijn altijd op zoek naar talent. Heb jij affiniteit met techniek, werk je graag in een informele sfeer en ben je op zoek naar een uitdagende baan of een leerzame stage? Dan zijn wij misschien op zoek naar jou.
                                    </p>       
                                    <a href="https://werkenbij.wijzijnumbrella.nl/" className="c-button c-button--white">Bekijk vacatures</a>
                                </div>
                            </div>
                        </div>
                    </section>
            
                    <section className="u-bg-white" id="contact" aria-label="Meer info">
                        <div className="u-constrain  u-spacing-pv++  u-text-center">
                            <h2 className="u-spacing-pb">Wij vertellen je graag meer over onze oplossingen...</h2>
                            <a href="https://wijzijnumbrella.nl/contact/" className="c-button">Meer weten</a>
                        </div>
                    </section>
            
                </main>
            
                <Footer />
            </>
        );
    }
}

export default Home;