import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import Privacy from '../pages/Privacy';
import React from 'react';
import NotFound from '../pages/NotFound';
import SkipContent from './SkipContent';

const Router = () => (
    <>
        <SkipContent></SkipContent>
        <BrowserRouter>
            <Switch>
                <Route exact path={"/"} component={Home} />
                <Route path={"/privacy"} component={Privacy} />
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    </>
);

export default Router;