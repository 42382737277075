import React from 'react';

const SkipContent = () => (
    <nav className="c-skip-content" aria-label="Overslaan">
        <ul role="menu">
            <li role="menuitem">
                <a className="c-button  c-skip-content__link" href="#main">Ga naar inhoud</a>
            </li>
            <li role="menuitem">
                <a className="c-button  c-skip-content__link" href="#footer">Ga naar pagina einde</a>
            </li>
        </ul>
    </nav>
);

export default SkipContent;