import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Icon from '../components/Icon';

class NotFound extends React.Component {
    static contextTypes = {
        router: () => true
    }

    componentDidMount(){
        document.title = "Oops! 404 - Qvision"
    }

    render() {
        return (
            <>
                <Header header="LogoOnly" />
        
                <main className="o-page__main" id="main">
                    <div className="u-constrain  u-spacing-pv++">
                        <div className="o-grid__row"> 
                            <div className="o-grid__item  o-arrange  o-arrange--center" data-fraction="9">
                                <div>
                                    <h1>Oops!</h1>
                                    <p>We kunnen deze pagina niet meer vinden!</p>
                                    <a href="/" className="c-button c-button--white">Terug naar de homepage</a>
                                </div>
                            </div>

                            <div className="o-grid__item" data-fraction="3">
                                <Icon icon="notFound" />
                            </div>
                        </div>
                    </div>
                </main>

                <Footer footer="small"/>
            </>
        )
    }
}

export default NotFound;